@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-file {
  display: flex;
  flex-direction: row;
  margin: 0px 0px 10px 0px;
  overflow: hidden;
  color: $ui-text;
  vertical-align: middle;
  padding: 5px;
  border: 1px solid $ui-light_grey;
  border-radius: 6px;
  min-height: 65px;

  &:hover {
    cursor: pointer;
  }

  .report-file--img-container {
    width: 70px;
    margin-right: 10px;
  }

  .report-file--content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .report-file-img {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: 100%;
    max-height: 70px;
    border-radius: 4px;
  }

  .report-file-doc {
    background-color: $ui-blue;
    color: white;
    text-align: center;
    line-height: 70px;
    font-size: 26px;
  }

  .report-file-name {
    font-size: 16px;
    font-family: "Campton-Bold";
    margin-bottom: 4px;
  }

  .report-file-text {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: $ui-subtitle;
  }

  .download-btn-container {
    position: absolute;
    z-index: 2;
    right: 0;
    margin-top: 2px;
    display: inline-block;
    text-align: center;
    vertical-align: bottom;
    margin-right: 22px;
    transition: all 0.2s ease-in;
    @media (max-width: 991px) {
      margin-right: 7px;
    }
    .download-btn,
    .delete-btn {
      height: 28px;
      width: 28px;
      padding: 6px;
      background-color: $ui-white;
      text-align: center;
      float: right;
      font-size: 12px;
    }
    .delete-btn {
      margin-right: 5px;
    }
  }
}

.report-file + .report-file {
  margin-top: 10px;
}
