@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-header {
  .header {
    text-align: left;
    @media (min-width: 576px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .header-title {
      display: inline-flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    .title {
      text-transform: capitalize;
      color: $ui-text;
      margin: 0px;
      font-family: "Campton-Medium";
      font-size: 32px;
    }

    .report-id {
      color: $ui-text;
      margin: 0px;
      font-weight: 500;
      font-size: 32px;
    }

    .report-information {
      color: $ui-dark_grey;
      margin: 0px 20px 0px 10px;
    }

    .right-content {
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 0.5rem;

      @media (max-width: 576px) {
        margin-bottom: 15px;
        display: block;
      }
    }
  }

  .header-report-info-bar {
    text-align: left;
    margin-bottom: 5px;
    .header-report-info-bar--list {
      overflow: hidden;
      padding: 0px;
      .fa-executive {
        color: $ui-light_blue;
        margin-left: 2px;
      }
    }
    .header-report-info-bar--text {
      @media (max-width: 576px) {
        display: block;
        margin-bottom: 5px;
      }
      display: inline;
      margin: 0;
      margin-right: 15px;
      color: $ui-subtitle;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .header-bottom {
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
    @media (min-width: 769px) and (max-width: 991px) {
      margin-bottom: 15px;
    }
    margin-bottom: $layout-padding-size;
    border-bottom: 1px solid $ui-light_grey;
  }
}
