@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.chart {
  padding: 12px;
  margin-bottom: 24px;
  border-radius: 12px;
  border: 1px solid $ui-light_grey;
  flex-basis: calc(100%);
  @media (min-width: 576px) and (max-width: 991px) {
    max-width: 48%;
  }
  @media (min-width: 991px) {
    max-width: 32%;
  }
  .chart-header {
    span {
      font-size: 15px;
      color: $ui-subtitle;
      text-transform: lowercase;
      display: inline-block;
      font-family: "Campton-Medium";
    }
  }
  h2 {
    font-size: 16px;
    margin-bottom: 0px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    height: 38px;
  }
  .custom-chart-container {
    width: 99%;
  }
}
.chart.anon-chart {
  max-width: 100%;
}
