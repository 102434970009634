@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.formats-selector {
  width: 100%;
  .formats-container {
    background-color: $ui-background;
    padding: 20px;
    width: 100%;
    border-radius: 8px;
    .format-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      .format-item,
      .el-radio {
        background-color: $ui-background;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        .el-radio__label {
          display: flex;
          align-items: center;
          i {
            margin-right: 10px;
            margin-left: 10px;
            font-size: 18px;
          }
          p {
            margin-bottom: 0px;
          }
        }
        .el-radio__input {
          display: flex;
        }
        .el-radio__input + .el-radio__label {
          color: $ui-subtitle;
          p {
            font-family: "Campton-Medium";
            font-size: 15px;
          }
          i {
            margin-left: 15px;
            margin-right: 20px;
          }
        }
        .el-radio__input.is-checked {
          .el-radio__inner {
            border-color: $ui-green;
            background-color: $ui-green;
          }
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: $ui-subtitle;
        }
      }
    }
  }
}
