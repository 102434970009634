@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.notme-button {
  display: inline-block;
  background: $ui-dark_blue;
  border-radius: 6px;
  align-items: center;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  border: 1px solid $ui-dark_blue;
  color: $ui-white;
  padding: 12px 14px;
  font-family: "Campton-Medium";
  font-size: 15px;
  line-height: 15px;

  .icon-spacing {
    display: inline;
    margin-right: 4px;
  }

  &:hover:enabled {
    opacity: 0.9;
  }

  &:active:enabled {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
}

.notme-button-full {
  display: block;
  width: 100%;
}

.notme-button-square {
  padding: 12px;
  height: 40px;
  width: 40px;
}

.notme-button-small-square {
  padding: 8px;
  font-size: 14px;
  height: 30px;
  width: 30px;
}

.notme-button-circle {
  border-radius: 30px;
}

.notme-button-shadow {
  &:hover {
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
  }
}

.spinner {
  font-size: 12px;
}

@mixin notme-button-theme($color, $text-color) {
  border: none;
  background: $color;
  color: $text-color;
}

@mixin notme-button-theme-outline($color, $background) {
  background: none;
  color: $color;
  border: 1px solid $color;
  &:hover {
    background: $background;
  }
}

@mixin notme-button-theme-plain($color) {
  background: none;
  color: $color;
  border: none;
  margin: 1px;
}

.notme-button-blue {
  @include notme-button-theme($ui-blue, $ui-white);
}
.notme-button-dark_blue {
  @include notme-button-theme($ui-dark_blue, $ui-white);
}
.notme-button-light_blue {
  @include notme-button-theme($ui-light_blue, $ui-white);
}
.notme-button-red {
  @include notme-button-theme($ui-red, $ui-white);
}
.notme-button-dark_red {
  @include notme-button-theme($ui-dark_red, $ui-white);
}
.notme-button-light_red {
  @include notme-button-theme($ui-light_red, $ui-white);
}
.notme-button-green {
  @include notme-button-theme($ui-green, $ui-white);
}
.notme-button-dark_green {
  @include notme-button-theme($ui-dark_green, $ui-white);
}
.notme-button-yellow {
  @include notme-button-theme($ui-yellow, $ui-white);
}
.notme-button-dark_yellow {
  @include notme-button-theme($ui-dark_yellow, $ui-white);
}
.notme-button-grey {
  @include notme-button-theme($ui-grey, $ui-white);
}
.notme-button-dark_grey {
  @include notme-button-theme($ui-dark_grey, $ui-white);
}
.notme-button-light_grey {
  @include notme-button-theme($ui-light_grey, $ui-text);
}
.notme-button-lighter_grey {
  @include notme-button-theme($ui-lighter_grey, $ui-text);
}

.notme-button-blue-outline {
  @include notme-button-theme-outline($ui-blue, $ui-blue-a10);
}
.notme-button-dark_blue-outline {
  @include notme-button-theme-outline($ui-dark_blue, $ui-dark_blue-a10);
}
.notme-button-light_blue-outline {
  @include notme-button-theme-outline($ui-light_blue, $ui-light_blue-a10);
}
.notme-button-red-outline {
  @include notme-button-theme-outline($ui-red, $ui-red-a10);
}
.notme-button-dark_red-outline {
  @include notme-button-theme-outline($ui-dark_red, $ui-dark_red-a10);
}
.notme-button-light_red-outline {
  @include notme-button-theme-outline($ui-light_red, $ui-light_red-a10);
}
.notme-button-green-outline {
  @include notme-button-theme-outline($ui-green, $ui-green-a10);
}
.notme-button-dark_green-outline {
  @include notme-button-theme-outline($ui-dark_green, $ui-dark_green-a10);
}
.notme-button-yellow-outline {
  @include notme-button-theme-outline($ui-yellow, $ui-yellow-a10);
}
.notme-button-dark_yellow-outline {
  @include notme-button-theme-outline($ui-dark_yellow, $ui-dark_yellow-a10);
}
.notme-button-grey-outline {
  @include notme-button-theme-outline($ui-grey, $ui-grey-a10);
}
.notme-button-dark_grey-outline {
  @include notme-button-theme-outline($ui-dark_grey, $ui-dark_grey-a10);
}
.notme-button-light_grey-outline {
  @include notme-button-theme-outline($ui-light_grey, $ui-light_grey-a10);
}
.notme-button-lighter_grey-outline {
  @include notme-button-theme-outline($ui-lighter_grey, $ui-lighter_grey-a10);
}

.notme-button-blue-plain {
  @include notme-button-theme-plain($ui-blue);
}
.notme-button-dark_blue-plain {
  @include notme-button-theme-plain($ui-dark_blue);
}
.notme-button-light_blue-plain {
  @include notme-button-theme-plain($ui-light_blue);
}
.notme-button-red-plain {
  @include notme-button-theme-plain($ui-red);
}
.notme-button-dark_red-plain {
  @include notme-button-theme-plain($ui-dark_red);
}
.notme-button-light_red-plain {
  @include notme-button-theme-plain($ui-light_red);
}
.notme-button-green-plain {
  @include notme-button-theme-plain($ui-green);
}
.notme-button-dark_green-plain {
  @include notme-button-theme-plain($ui-dark_green);
}
.notme-button-yellow-plain {
  @include notme-button-theme-plain($ui-yellow);
}
.notme-button-dark_yellow-plain {
  @include notme-button-theme-plain($ui-dark_yellow);
}
.notme-button-grey-plain {
  @include notme-button-theme-plain($ui-grey);
}
.notme-button-dark_grey-plain {
  @include notme-button-theme-plain($ui-dark_grey);
}
.notme-button-light_grey-plain {
  @include notme-button-theme-plain($ui-light_grey);
}
.notme-button-lighter_grey-plain {
  @include notme-button-theme-plain($ui-lighter_grey);
}
