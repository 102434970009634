@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.authentication-layout {
  height: 100vh;
  margin: 0;

  .main {
    margin: 0;
    height: 100%;

    a {
      font-family: "Campton-Bold";
      color: $ui-dark_red;

      &:hover {
        font-family: "Campton-Medium";
        text-decoration: none;
      }
    }

    .authentication-left-content {
      text-align: left;
      background-color: white;
      padding: 0;
      display: flex;
      flex-direction: column;

      .authentication-left {
        flex-grow: 1;
        padding: 25px 100px;
        @media (max-width: 1140px) {
          padding: 25px 50px;
        }

        .authentication-header {
          .authentication-header-title {
            font-size: 36px;
          }

          .authentication-header-subtitle {
            color: $ui-blue;
            font-size: 18px;
          }
          margin-bottom: 30px;
        }
      }
      .bottom-banner {
        border-top: 1px $ui-light_grey solid;
        font-size: 12px;
        line-height: 16px;
        padding: 5px 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width: 576px) {
          flex-direction: column;
        }

        .el-input__inner {
          border: none;
          color: $ui-subtitle;
          font-family: "Campton-Bold";
          padding: 0px;
        }

        .bottom-banner--links {
          list-style: none;
          margin: 6px -16px 0px -16px;
          padding: 0;
          .bottom-banner--link {
            margin: 0;
            display: inline-block;
            @media (max-width: 576px) {
              display: block;
            }
            .bottom-banner--link-text {
              display: inline-block;
              color: $ui-subtitle;
              margin-top: -6px;
              padding: 6px 16px;
              cursor: pointer;
              transition: all 0.25s;
              &:hover {
                color: $ui-text;
              }
            }
          }
        }
      }
    }

    .authentication-right-content {
      position: fixed;
      right: 0;
      height: 100%;
      padding: 0;
      overflow: hidden;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
    }
  }
}
