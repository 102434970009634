@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.resolution-header {
  margin-top: -15px;
  .resolution-header_info {
    margin-bottom: 10px;
    font-size: 14px;
    color: $ui-subtitle;
    font-family: "Campton-Medium";
    span {
      font-family: "Campton-Bold";
    }
  }
}
