@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-sidebar {
  @media (max-width: 991px) {
    padding: 0px;
  }
  .sidebar-line {
    border-top: 1px solid $ui-light_grey;
    margin-bottom: 20px;
  }
}
