@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.text-category-cell {
  padding: 6px 6px;
  cursor: pointer;
  &:hover {
    background: $ui-lighter_grey;
  }

  .text-category-cell-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    .color-icon {
      color: var(--color);
      font-size: 10px;
    }
    .text-category-cell-text {
      font-size: 15px;
      margin: 0px;
      margin-bottom: -3px;
      text-align: left;
      font-family: "Campton-Medium";
    }
  }
}
