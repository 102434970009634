
@import '@/styles/variables.scss';

.main-content__top {
  position: fixed;
  top: 0;
  margin-left: -15px;
  width: calc(100% -  #{$sidebar-width-expanded});
  height: 60px;
  border-bottom: 1px solid #eee;
  background: #fff;
  z-index: 1000;
}

.main-content__title {
  font-size: 1.4em;
  margin: 15px 0 15px 15px;
  border-bottom: none;
}

.main-content__body {
  margin-top: 80px;
}