@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.btn-message {
  height: 60px;
  width: 60px;
  position: fixed;
  z-index: 3;
  right: 0;
  bottom: 0;
  line-height: 20px;
  margin-right: $layout-padding-size;
  margin-bottom: $layout-padding-size;
  font-size: 20px;
  @media (max-width: 768px) {
    margin-right: $layout-padding-size-mobile;
    margin-bottom: $layout-padding-size-mobile;
  }
}

.message-box {
  width: 376px;
  border-radius: $widget-radius;
  bottom: 100px;
  padding: 0px;
  z-index: 214;
  min-height: 250px;
  max-height: 604px;
  height: calc(100% - 130px);
  position: fixed;
  right: $layout-padding-size;
  background-color: white;
  box-shadow: $widget-shadow;
  opacity: 1;
  overflow: hidden;
  display: block;
  @media (max-width: 767px) {
    height: calc(100% - 190px);
    right: $layout-padding-size-mobile;
  }

  @media (max-width: 576px) {
    min-height: 250px;
    opacity: 1;
    z-index: 200001;
    width: 100%;
    height: 100%;
    max-height: none;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    overflow: hidden;
    border-radius: 0px;
  }

  .message-box-content {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;

    .chat-header {
      position: relative;
      min-height: 75px;
      transition: height 160ms ease-out 0s;
      padding: 10px;
      color: $ui-text;
      .header-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $ui-light_grey;
        .right-btn {
          height: 32px;
          width: 32px;
          margin-left: auto;
          margin-right: 0;
          padding: 5px;
          align-self: center;
          text-align: center;
          border-radius: 5px;
          vertical-align: middle;
          &:hover {
            background-color: $ui-light_grey;
            color: $ui-red;
          }
        }
      }
      h5 {
        font-family: "Campton-Medium";
        font-size: 28px;
      }
      p {
        border-radius: $widget-radius;
        padding: 5px;
        text-align: center;
        margin: 10px 0px 10px 0px;
        font-size: 14px;
        background: #eee;
        color: #000;
      }
    }

    .content {
      position: relative;
      background-color: white;
      flex: 1 1 0%;

      .el-loading-mask {
        .circular {
          margin-top: 3px;
          height: 14px;
          width: 14px;
        }
      }

      .box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        overflow-x: hidden;
        outline-offset: -5px;

        #chat-container {
          overflow-y: auto;
          padding: 10px;
        }
      }
      .footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        min-height: 56px;
        max-height: 200px;
        border-top: 1px solid rgb(230, 230, 230);
        background-color: #fff;
        padding: 0px;

        .type_msg {
          border: 0 !important;
          color: black !important;
          height: 60px !important;
          overflow-y: auto;
          resize: none;
          border-radius: 0 0 0 0;
        }
        .type_msg:focus {
          box-shadow: none !important;
          outline: 0px !important;
        }
        .send_btn {
          border-radius: 0 0 0 0 !important;
          background: none;
          border: 0 !important;
          color: $ui-red;
          cursor: pointer;
        }
        .input-group-append {
          border-left: 1px solid #e6e6e6;
          &:after {
            border-left: none;
          }
        }
      }
    }

    .welcome-admin {
      margin: 32px 24px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: $ui-text;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
