@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.company-search-container {
  width: 100%;
  a.clear-filter {
    float: right;
    font-size: 12px;
    font-weight: 700;
    padding-top: 2px;
    color: $ui-red;
    margin-top: -25px;
    cursor: pointer;
    &:hover {
      color: $ui-dark_blue;
    }
  }
  .company-search {
    border-radius: 10px;
    text-align: left;
    margin-bottom: 16px;
    .option__image {
      width: 18px;
      display: inline-block;
      vertical-align: middle;
      object-fit: contain;
    }
    .multiselect__content-wrapper {
      ul {
        li {
          &:first-child {
            margin-top: 0px;
          }
          .option__desc {
            display: flex;
            justify-items: center;
            align-items: center;
            .option__title {
              display: flex;
              justify-items: center;
              align-items: center;
            }
          }
        }
      }
    }

    .multiselect__option {
      display: flex;
      flex-direction: row;
      white-space: pre-wrap;
      .option__title {
        font-size: 14px;
        margin-left: 5px;
        color: $ui-dark_blue;
        font-family: "Campton-Medium" !important;
      }
    }
    .multiselect__tags {
      .multiselect__input {
        background-color: inherit;
      }
      input::placeholder {
        color: $ui-grey;
        font-size: 14px;
      }
      .multiselect__placeholder {
        color: $ui-grey;
      }
    }
    .multiselect__option--highlight {
      background-color: $ui-light_grey !important;
      color: $ui-dark_blue;
    }

    .multiselect__tag {
      background: none;
      border: 1px solid $ui-grey;
      color: $ui-dark_blue;
      font-weight: 700;
      border-radius: 5px;
      margin-right: 0px;
      margin-bottom: 0px;
      white-space: break-spaces;
      text-overflow: inherit;
      margin-right: 5px;
      .multiselect__tag-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: none;
        }
        &:hover:after {
          font-weight: 700;
          color: $ui-red;
        }
        &:after {
          color: $ui-dark_blue;
          height: 20px;
          width: 20px;
        }
      }
    }

    .master-selector--title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;
      .master-selector--title-text {
        margin: 0px 10px 0px 0px;
        color: $ui-dark_blue;
        font-weight: 700;
      }

      .info-circle {
        color: $ui-dark_grey;
        align-self: center;
        padding-top: 4px;
        font-size: 16px;
      }
    }

    .el-input__inner {
      border-radius: 6px;
    }
  }
}
