@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.search-query-cell {
  background-color: $ui-white;
  border-radius: 4px;
  font-family: "Campton-Medium";
  font-size: 14px;
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 4px;
  justify-content: center;
  align-items: center;
  .search-query-cell-title {
    padding: 0px 6px;
    margin: 0px;
    text-transform: capitalize;
  }
  .search-query-cell-value-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    background-color: $ui-light_grey;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 10px;
    .remove-button {
      cursor: pointer;
      color: $ui-grey;
      transition: 0.25s ease-in-out;
      &:hover {
        color: $ui-text;
      }
    }
  }
}
