@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.information-popover {
  border-radius: $widget-radius;
  border: none;
  -webkit-box-shadow: $widget-shadow;
  -moz-box-shadow: $widget-shadow;
  box-shadow: $widget-shadow;
  font-family: "Campton-Book";
  color: $ui-text;

  p {
    margin: 0;
    word-break: normal;
    text-align: left;
  }
}
