@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.behavior-cell {
  transition: all 0.1s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }

  .behavior-row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .behavior-container {
    display: flex;
    flex-direction: row;

    .left-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }
    .right-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }

    .behavior-title {
      text-align: left;
      color: $ui-text;
      font-family: "Campton-Medium";
    }
  }
}
