@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in;
  max-height: 230px;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
  max-height: 0px;
}
.el-picker-panel__sidebar {
  width: 150px !important;
}
.el-picker-panel__body {
  margin-left: 150px !important;
}

.analytics {
  display: flex;
  flex-direction: column;
  .wrapper {
    display: flex;
    justify-content: center;
    .analytics-container {
      max-width: 720px;
      padding-bottom: 40px;
    }
  }
  .toggle-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    .el-checkbox {
      margin-bottom: 16px;
      .el-checkbox__label {
        font-family: "Campton-Medium";
        font-size: 16px;
        color: $ui-grey;
      }
    }
    .el-checkbox.is-checked {
      .el-checkbox__inner {
        background-color: $ui-green;
        border-color: $ui-green;
      }
      .el-checkbox__label {
        p {
          color: $ui-dark_blue;
        }
      }
    }
  }

  .header-container {
    .header-text {
      color: $ui-text;
      font-family: "Campton-Bold";
      font-size: 28px;
      text-align: left;
      padding-bottom: 8px;
    }
    .description-text {
      color: $ui-subtitle;
      font-family: "Campton-Medium";
      font-size: 16px;
      text-align: left;
    }
  }
  .table-analytics {
    text-align: left;
    .el-date-editor {
      width: 100%;
      justify-content: center;
      height: 36px;
    }
    .range-label {
      font-family: "Campton-Medium" !important;
      color: $ui-dark_grey;
      text-transform: uppercase;
      letter-spacing: 0.75px;
      display: block;
      margin: 0 0 8px;
      font-size: 12px;
      font-family: "Campton-Medium";
      line-height: 1.5em;
    }
  }

  .analytics-card {
    margin-top: 20px;
    padding: 16px;
    background-color: $ui-lighter_grey;
    border-radius: 12px;
    .analytics-card--text {
      color: $ui-text;
      font-family: "Campton-Medium";
      font-size: 14px;
      margin: 0 0 10px 0;
    }
  }
}
