@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

li {
  display: inline-block;
  clear: both;
  padding: 10px;
  border-radius: 25px;
  margin-bottom: 4px;
  max-width: 220pt;

  .text-content {
    margin: 0px;
  }

  .date {
    margin: 0px;
    font-size: 12px;
  }
}

.receiver {
  float: left;
  text-align: left;
  background: #eee;
  color: #000;
}

.subscriber {
  float: right;
  text-align: right;
  background: $ui-red;
  color: #fff;
}

.info {
  text-align: center;
  background: #eee;
  color: $ui-red;
  max-width: 100% !important;
  width: 100% !important;
  margin: 10px 0px 10px 0px;
  font-size: 14px;
  border-radius: 10px;
}
