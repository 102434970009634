@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-resolution-modal {
  .custom-modal-wrapper {
    .custom-modal-container {
      background-color: $ui-background;
    }
  }
  &__content {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .custom-modal-header {
    h5 {
      text-align: left;
    }
  }

  &__body {
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: none; /* For Firefox */
  }

  &__body::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    gap: 10px;
  }

  &__steps-indicator {
    font-weight: bold;
    text-align: right;
    margin-top: 5px;
    font-size: 12px;
    color: $ui-blue;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
