@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.sidebar {
  width: 100%;
  .top-widget {
    padding-bottom: 10px;
  }
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    a {
      height: min-content;
    }
    p {
      margin-bottom: 0px;
      font-family: "Campton-Medium";
      color: $ui-blue;
      font-size: 14px;
    }
  }
  .sidebar-title {
    margin-bottom: 0px;
    text-align: left;
    font-size: 17px;
  }
  .users-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
    padding-top: 10px;
    gap: 10px;
    .avatar-container {
      padding-bottom: 10px;
      .notme-avatar {
        border-radius: 8px;
        margin-left: 0px;
        .notme-avatar-text {
          top: 0px !important;
        }
      }
    }
  }
  .reports-container {
    padding-top: 10px;
  }
  .report-aging-container {
    padding-bottom: 30px;
  }
}
