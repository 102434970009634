@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-tabs {
  @media (max-width: 768px) {
    padding-top: 10px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-bottom: 10px;
  }
  li {
    float: left;
    cursor: pointer;
    border-radius: 6px;
    margin-right: 10px;
    span {
      display: block;
      text-align: center;
      padding: 8px 12px;
      line-height: 10px;
      font-family: "Campton-Medium";
      color: $ui-subtitle;
      text-decoration: none;
      i {
        padding-right: 4px;
      }
    }
  }
  .active {
    background-color: $ui-lighter_grey;
    span {
      color: $ui-blue;
    }
  }
}
