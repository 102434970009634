@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.graphs-selector {
  width: 100%;
  .categories-container {
    background-color: $ui-background;
    padding: 20px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .category-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin-bottom: 8px;
      .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        h5 {
          font-size: 18px;
          text-transform: capitalize;
          margin-bottom: 0px;
        }
        a {
          color: $ui-green;
          font-size: 12px;
          font-weight: 700;
          text-decoration: none;
          cursor: pointer;
        }
      }
      .graph-item {
        background-color: $ui-background;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 5px;
        i {
          margin-right: 10px;
          margin-left: 10px;
        }
        .el-checkbox {
          margin-bottom: 0px;
          width: 100%;
          display: flex;
          padding: 10px;
          .el-checkbox__label {
            display: flex;
            white-space: normal;
            p {
              text-align: left;
              margin: 0px;
            }
          }
        }
        .el-checkbox.is-checked {
          i {
            color: $ui-blue;
          }
        }
      }
    }
  }
}
