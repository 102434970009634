@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.company-logo-container {
  cursor: pointer;
  p {
    font-family: "Campton-Medium";
    color: $ui-text;
  }
  .company-logo {
    margin-right: 10px !important;
  }
  .right-btn {
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-left: auto;
    font-size: 10px;
  }
}
