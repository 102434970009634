@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.conclusion-summary {
  display: flex;
  flex-direction: column;
  .conclusion-title {
    font-size: 18px;
    text-align: left;
    font-family: "Campton-Bold";
    margin-bottom: 6px;
  }
  .conclusion-subtitle {
    font-size: 14px;
    text-align: left;
    font-family: "Campton-Bold";
    margin-bottom: 6px;
  }

  .section-block {
    margin-bottom: 10px;
  }

  .conclusions_selected {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    margin-bottom: 6px;

    .notme-tag {
      white-space: nowrap;
      font-size: 13px;
      margin-right: 0px;
    }
  }

  .wrapped-conclusions {
    .notme-tag {
      white-space: normal;
    }
  }

  .text-details {
    margin-top: 10px;
    font-family: "Campton-Medium";
    text-align: left;
    font-size: 14px;
    line-height: 14px;
  }
}
