@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.navbar {
  font-size: 16px;
  height: 66px;
  background-color: $ui-card;
  border-bottom: 1px solid transparent;
  margin-left: -$layout-padding-size;
  margin-right: -$layout-padding-size;

  @media (max-width: 768px) {
    margin-left: -$layout-padding-size-mobile;
    margin-right: -$layout-padding-size-mobile;
  }
  margin-bottom: 20px;
  -webkit-box-shadow: $widget-shadow;
  -moz-box-shadow: $widget-shadow;
  box-shadow: $widget-shadow;

  .navbar-hamburger-left {
    position: absolute;
    left: 1rem;
    color: $ui-text;
  }
}
