@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.module-selector--item-icon {
  color: $ui-text;
  margin-right: 10px;
}

.module-selector {
  flex-direction: column;
  margin-bottom: 16px;

  .label {
    margin-bottom: 0.2em;
    font-family: "Campton-Medium";
    font-size: 16px;
    color: $ui-text;
  }

  .module-selector--label {
    color: $ui-text;
  }

  .el-select {
    display: inherit;

    .el-input {
      font-size: inherit;
    }

    .el-input__inner {
      -webkit-appearance: none;
      outline: none;
      border-radius: 0px;
      border: 1px solid $ui-light_grey;
      border-radius: 6px;
      background-color: $ui-card;
      color: $ui-text;
      padding: 1em;
      width: 100%;
      height: 3em;
      &:focus {
        outline: none;
        border: 1px solid $ui-light_blue;
      }
    }
  }
}
