@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.forgot-password-container {
  .forgot-password {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 10px;
  }
  .forgot-password-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
