@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.timeline-event {
  .evidence {
    display: flex;
    flex: row;
    flex-wrap: wrap;
    .report-file {
      margin-top: 0px;
      border-width: 0px;
      position: relative;
      margin-bottom: 0px;
      padding-left: 0px;
      .download-btn-container {
        position: absolute !important;
        margin-right: 20px;
        margin-top: 5px;
      }
      .report-file--content-container {
        display: none;
      }
      .report-file--img-container {
        width: 120px;
        height: 120px;
        .report-file-img {
          max-height: 120px;
          border-radius: 7px;
        }
      }
    }
    .empty-evidence {
      display: flex;
      margin-top: 10px;
      width: 120px;
      height: 120px;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 10px;
      border-radius: 7px;
      background-color: $ui-background;
      .empty-evidence-icon {
        font-size: 50px;
        color: $ui-grey;
      }
      .empty-evidence-cross {
        color: $ui-background;
        position: absolute;
        margin-top: 10px;
        font-size: 20px;
      }
    }
  }
}
