@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.assign-button {
  font-family: "Campton-Book";
  .search-container {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .form-control {
    padding: 0px;
    position: relative;
    .form-control-input {
      border-radius: 0.25rem;
      border-width: 0px;
      border-color: transparent;
      width: 100%;
      padding: 6px 12px 6px 12px;
    }
    .selected-company-container {
      padding: 6px 12px 6px 12px;
    }
  }

  .title {
    color: $ui-text;
    font-size: 16px;
    font-family: "Campton-Bold";
    border-bottom: 1px solid $ui-light_grey;
  }
  .subtitle {
    text-transform: uppercase;
    color: $ui-subtitle;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: "Campton-Medium";
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }

  .scrollable-list {
    max-height: calc(4 * 60px + 20px);
    padding: 0;
    margin: 0;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
