@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.assignee-pile {
  display: flex;
  align-items: center;
  list-style-type: none;
  line-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 16px;

  li {
    margin-top: -3px;
    border-radius: 50%;
    border: 3px solid white;
    margin-left: -10px;
  }

  .avatar-circle {
    @include avatar(35px);
  }
}
