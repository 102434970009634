@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.avatar-base-name {
  display: flex;
  flex-direction: row;

  .avatar-base-name--name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 10px;

    .avatar-base-name--name-title {
      color: $ui-text;
      font-family: "Campton-Medium";
      margin: 0;
    }
  }
}
