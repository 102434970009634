@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.top-widget-content {
  // border-left-width: 1.25rem;
  // border-left-style: solid;
  height: 100%;
  transition: all 0.1s ease-in-out;
}

.top-widget-content.widget-box {
  box-shadow: none;
}

a {
  color: $ui-text;
  &:hover {
    text-decoration: none;
  }
}

.img-top {
  margin-left: -30px;
  margin-top: auto;
  margin-bottom: auto;
}

.widget-body {
  padding: 1rem;
  text-align: left;
  display: flex;
  flex-direction: row;

  .left-icon {
    padding-left: 20px;
    padding-right: 20px;
    align-self: center;
  }

  .no-padding {
    // max-height: 50px;
    // background-color: #f40a12;
    padding: 0 10px 0px 0px;
  }
}

.widget-inner-left {
  padding-left: 10px;
  text-align: left;
}

.widget-icon {
  font-family: "Avenir";
  display: inline-block;
  font-size: 40px;
  line-height: 40px;
  background-color: var(--color-bg);
  // background-color: rgba($ui-red, 0.1);
  // background-color: rgba( #c6538c, 0.1);
  // background: rgba(var(--color), 0.1);
  // @include background-opacity(var(--color), 0.1);
  // background-color: #c1473a;    // background-color: var(--color);
  color: white;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 6px;
  margin-right: 1rem;
  vertical-align: bottom;
  flex: 0 0 50px;

  .widget-icon-img {
    font-size: 20px;
    text-align: center;
    display: inline;
    line-height: normal;
    vertical-align: baseline;
    top: 25%;
    color: var(--color);
  }

  // vertical-align: middle;
  // margin: 0 auto;
  // right: 0px;
  // float: right;
  // background-color: #c1473a;
  // height: 50px;
  // width: 50px;
  // border-radius: 25px;
  // background-image: url('../../../../static/icons-widget/todo-list.png');
  // background-repeat: no-repeat;
  // background-size: 30px;
  // background-position: center;
}
.widget-logo {
  vertical-align: middle;
  margin: 0 auto;
  right: 0px;
  float: right;
  max-height: 50px;
}

.data-nb-style {
  color: $ui-text;
  font-family: "Campton-Medium";
  margin-bottom: 0px;
  font-size: 22px;
}

.data-title-style {
  font-family: "Campton-Medium" !important;
  color: $ui-subtitle;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
}
