@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.custom-modal {
  position: fixed;
  z-index: 2001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: table;
  transition: opacity 0.3s ease;

  .custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .custom-modal-container {
      background-color: $ui-card;
      margin: 0px auto;
      padding: 20px;
      border-radius: $widget-radius;
      max-width: 400px;
      transition: all 0.3s ease;

      .header {
        @media (max-width: 576px) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        margin-bottom: 16px;
      }

      h4 {
        font-size: 26px;
      }

      h6 {
        font-size: 14px;
        color: $ui-subtitle;
      }

      .close-button {
        float: right;
        font-size: 12px;
        font-family: "Campton-Medium";
        padding-top: 2px;
        color: $ui-red;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $ui-text;
        }
      }

      .btn-shape {
        border-radius: 20px;
        border-width: 0px;
        height: 35px !important;
        margin-left: 12px;
        &:hover {
          -webkit-box-shadow: $widget-shadow;
          -moz-box-shadow: $widget-shadow;
          box-shadow: $widget-shadow;
        }
      }

      .btn-shape.btn-top {
        vertical-align: top;
        color: $ui-text;
        background-color: $ui-white;
        font-size: 10px;
        width: 35px;
        border: $ui-light_grey solid 1px;
      }
    }
  }
}

.modal-transition-enter {
  opacity: 0;
}

.modal-transition-leave-active {
  opacity: 0;
}

.modal-transition-enter .custom-modal-container,
.modal-transition-leave-active .custom-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
