@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in;
  max-height: 230px;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
  max-height: 0px;
}

.accused {
  .row-filters {
    text-align: left;
    margin-bottom: 20px;

    .clear-filter {
      float: right;
      font-size: 12px;
      font-family: "Campton-Medium";
      padding-top: 2px;
      color: $ui-red;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $ui-text;
      }
    }

    h6 {
      text-transform: uppercase;
      letter-spacing: 0.75px;
      color: #999;
      display: block;
      margin: 0 0 8px;
      font-size: 12px;
      font-family: "Campton-Medium";
      line-height: 1.5em;
    }
  }

  .loader-middle {
    top: 200px;
  }
}
