@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.sidebar-cell_router-link {
  position: relative;
  padding: 5px 5px 5px 5px;
  margin: 5px 20px 5px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: $ui-subtitle;
  transition:
    color 0.5s,
    background-color 1s,
    font-weight 0.1s ease;
  font-family: "Campton-Book";
}

.sidebar-cell_router-link:hover {
  font-family: "Campton-Medium";
}

.sidebar-cell_router-link.router-link-active,
.sidebar-cell_router-link.router-link-exact-active {
  color: $ui-text;
  text-decoration: none;
  background-color: $ui-lighter_grey;
  font-family: "Campton-Medium";
  border-radius: 5px;
  .icon-container {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.separator {
  border-bottom: 0px solid $ui-light_grey;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin: 30px 0px 30px 25px;
}

.sidebar-cell_router-link:hover {
  opacity: 0.85;
}

.menu_icon {
  // font-size: 16px;
  // // width: 16px;
  // text-align: center;
  // margin-right: 10px;
  // color: white;
  font-size: 12px;
  text-align: center;
  display: inline;
  line-height: normal;
  vertical-align: baseline;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
}

.menu_title {
  font-size: 15px;
  letter-spacing: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.icon-button {
  margin-right: 16px;
}

.icon-container {
  // background-color: var(--color);
  // border-radius: 6px;
  // padding: 10px;
  font-family: "Avenir";
  display: inline-block;
  font-size: 40px;
  line-height: 9px;
  background-color: var(--color);
  color: white;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 5px;
  vertical-align: bottom;
  margin-right: 16px;
}
