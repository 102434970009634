@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

.login {
  .login-header {
    text-align: left;
    margin-bottom: 30px;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
}
