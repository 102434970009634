@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.conclusion-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  .notme--text-area--label {
    display: flex;
    font-size: 14px;
    text-align: left;
    font-family: "Campton-Medium";
    margin-bottom: 6px;
  }
  .notme--text-area {
    display: flex;
    flex: 1;
    .text-area {
      height: 100%;
      border-radius: 8px;
      font-family: "Campton-Medium";
      line-height: 18px;
      &:focus {
        border-color: $ui-light_blue;
      }
    }
  }
}
