@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .executive-tag {
    margin-top: 10px;
    margin-bottom: -10px;
    width: min-content;
    i {
      margin-right: 5px;
    }
  }
}
