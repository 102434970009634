@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-timeline-container {
  .timeline-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    position: relative;
    .multiselect {
      position: absolute;
      width: 170px;
      z-index: 1;
      right: 0;
      bottom: -50px;
      .multiselect__select {
        display: none;
      }
      .multiselect__tags {
        display: none;
        .multiselect__placeholder {
          font-family: "Campton-Medium";
          color: $ui-text;
          font-size: 16px;
          margin-bottom: 0px;
        }
      }
      .multiselect__content-wrapper {
        background-color: $ui-card;
        -webkit-box-shadow: $widget-shadow;
        -moz-box-shadow: $widget-shadow;
        box-shadow: $widget-shadow;
        border-radius: 6px;
        .multiselect__option--selected {
          background-color: $ui-card;
          font-weight: 400;
        }
        .multiselect__option--highlight {
          background-color: $ui-card;
        }
        .option__desc {
          display: flex;
          flex-direction: row;
          align-items: center;
          i {
            width: 15px;
            color: $ui-blue;
          }
          .option__title {
            margin-left: 10px;
            font-size: 14px;
            color: $ui-text;
            font-family: "Campton-Medium" !important;
          }
          .option__active {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            i {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .timeline-header {
    background-color: $ui-background;
    border-radius: 10px;
    padding: 10px 15px;
    text-align: left;
    font-family: "Campton-Medium";
    span {
      font-family: "Campton-Bold";
      color: $ui-text;
    }
    .header-content {
      display: inline;
      padding: 0px;
      margin: 0px;
    }
  }
  .timeline-events-container {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 25px;
  }
  .loader-timeline {
    position: relative !important;
    width: 100%;
    height: 50px;
  }
}
