@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.sidebar-container {
  z-index: 1000;
  position: fixed;
  left: $sidebar-width-expanded;
  width: $sidebar-width-expanded;
  height: 100%;
  margin-left: -$sidebar-width-expanded;
  background: $ui-card;
  top: 0;
  transition: all 0.5s ease;
  border-right: 1px solid $ui-light_grey;
  // -webkit-box-shadow: $widget-shadow;
  // -moz-box-shadow: $widget-shadow;
  // box-shadow: $widget-shadow;
  // border-top-right-radius: 8px;
  // border-bottom-right-radius: 8px;
}
