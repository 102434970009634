@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

@import "./styles/fonts/CamptonFontKit.css";

#app {
  font-family: "Campton-Book", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: $ui-card;
  color: #001b39;
  width: 100%;
  height: 100%;

  h1 {
    line-height: 1;
  }

  h1,
  h2,
  h3 {
    font-family: "Campton-Bold";
  }

  h4 {
    font-family: "Campton-Bold";
  }

  h5,
  h6 {
    font-family: "Campton-Bold";
  }
}
