@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-category-cell {
  padding: 6px 6px 6px 8px;
  cursor: pointer;
  &:hover {
    background: $ui-lighter_grey;
  }
  .report-category-cell-content {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
    .report-status-icon {
      .report-icon {
        display: inline-block;
        color: $ui-subtitle;
        font-size: 24px;
      }
      .status-icon {
        border: 3px solid $ui-card;
        border-radius: 10px;
        bottom: -4px;
        color: rgba(var(--color), 1);
        font-size: 8px;
        position: absolute;
        right: -8px;
      }
    }
    .report-category-texts {
      .report-info,
      .report-date {
        margin: 0px;
        text-align: left;
        font-family: "Campton-Medium";
      }
      .report-info {
        font-size: 15px;
      }
      .report-date {
        font-size: 14px;
        color: $ui-subtitle;
      }
    }
  }
}
