@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.button-arrow {
  @media (max-width: 576px) {
    margin: 0 0 8px;
    display: block;
    width: 100%;
  }
  .arrow-spacing {
    margin-left: 8px;
  }
  span {
    background-color: $ui-blue;
    color: $ui-white;
    font-size: 15px;
    padding: 3px 5px 1px 5px;
    margin-right: -5px;
    margin-left: 2px;
    border-radius: 3px;
  }
}
