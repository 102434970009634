@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.row-height {
  @media (min-width: 991px) {
    margin-right: -30px;
    height: 100vh;
    .dashboard-main,
    .dashboard-sidebar {
      padding-top: 15px;
      height: 100%;
      overflow-y: scroll;
      .top-widget {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    .dashboard-sidebar {
      display: flex;
      border-left: 1px solid $ui-light_grey;
      .top-widget {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        height: min-content;
        .widget-body {
          padding: 0px;
        }
      }
    }
  }
  @media (min-width: 576px) {
    .right-content {
      .el-dropdown {
        margin-bottom: 8px;
      }
    }
  }
  @media (max-width: 991px) {
    .dashboard-main {
      padding-right: 0px;
      .row {
        margin-right: 0px;
        margin-left: 0px;
      }
    }
    .dashboard-sidebar {
      padding-right: 0px;
    }
    .sidebar {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 30px;
      .top-widget {
        padding: 0px;
      }
    }
    .dashboard-header {
      padding-right: 15px;
      padding-left: 15px;
      padding-top: 20px;
    }
  }
}

.overview {
  margin-left: -32px;
  width: calc(100% + 47px);
  margin-top: -15px;
}
.charts-row {
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 32px;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
  }
  .chart.top-chart {
    width: 100% !important;
    max-width: 49% !important;
    @media (min-width: 768px) and (max-width: 1600px) {
      .custom-chart-container {
        max-width: 280px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
    @media (min-width: 1600px) {
      .custom-chart-container {
        max-width: 340px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }
}

.dashboard-main {
  .banner-container {
    h4 {
      color: $ui-text;
    }
    h5 {
      color: $ui-subtitle;
      font-size: 18px;
    }
  }
  .el-button--dropdown {
    border-radius: 20px;
    background-color: $ui-lighter_grey;
    color: $ui-text;
    border-width: 0px;
    &:hover {
      -webkit-box-shadow: $widget-shadow;
      -moz-box-shadow: $widget-shadow;
      box-shadow: $widget-shadow;
    }
  }
  .title-dashboard {
    text-align: left;
    margin-bottom: 20px;
  }
  @media (max-width: 576px) {
    .el-range-editor--daterange,
    .el-input__inner {
      width: 100% !important;
    }
  }
  .el-range-editor--daterange,
  .el-input__inner {
    justify-content: space-around;
  }
  .row-flex-shelf {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    .widget-container {
      padding-left: 0px;
      padding-right: 0px;
      .widget-body {
        padding-left: 0px;
      }
      flex-basis: calc(100%);
      @media (min-width: 576px) and (max-width: 991px) {
        max-width: 48%;
      }
      @media (min-width: 991px) {
        max-width: 32%;
      }
    }
  }
}

.loader-middle {
  top: 200px;
}

.widget0-style {
  color: $ui-red;
}

.widget1-style {
  color: $ui-text;
}

.widget2-style {
  color: $ui-blue;
}

.widget3-style {
  color: $ui-light_blue;
}
