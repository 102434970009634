@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~effect-input/dist/index.css";

.two-factor {
  .two-factor-header {
    margin-bottom: 30px;
  }
  .indications {
    color: $ui-subtitle !important;
    font-size: 0.8rem;
  }
  .mfa-resend {
    cursor: pointer;
    color: $ui-light_blue;
  }
  .is-disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
}
