@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.search-bar-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  .search-bar {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    background-color: $ui-lighter_grey;
    border-radius: 6px;
    padding: 6px 14px;
    .search-bar-content {
      flex: 1;
      position: relative;
      min-width: 0;
      height: 28px;
      .search-bar-scroll-container {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        .query-list {
          display: flex;
          flex: 1;
          padding-left: 0px;
          position: relative;
          margin-bottom: 0;
          width: 1px;
          white-space: nowrap;
          gap: 10px;
          list-style: none;
          .query-list-item {
            border-radius: 4px;
            padding: 0px 4px;
            &:last-child {
              flex: 1;
              max-width: inherit;
            }
          }
        }
        .search-input {
          background-color: $ui-lighter_grey;
          position: relative;
          font-family: "Campton-Medium";
          min-width: 220px;
          border: none;
          padding: 0;
          height: 28px;
          line-height: inherit;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .search-clear-button {
      cursor: pointer;
      color: $ui-grey;
      transition: 0.25s ease-in-out;
      &:hover {
        color: $ui-text;
      }
    }
    .side-icon {
      width: 20px;
    }
  }

  .search-box-fade-enter-active,
  .search-box-fade-leave-active {
    transition: opacity 0.25s;
  }
  .search-box-fade-enter,
  .search-box-fade-leave-to {
    opacity: 0;
  }
}
