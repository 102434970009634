@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.assign-button {
  font-family: "Campton-Book";
  .title {
    color: $ui-text;
    font-size: 16px;
    font-family: "Campton-Bold";
    border-bottom: 1px solid $ui-light_grey;
  }
  .subtitle {
    text-transform: uppercase;
    color: $ui-subtitle;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: "Campton-Medium";
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }

  .scrollable-list {
    max-height: calc(4 * 60px + 20px);
    padding: 0;
    margin: 0;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .users-to-add {
    li:first-child {
      padding-top: 0px;
    }
  }
}
