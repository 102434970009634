@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.pagination-component {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  .el-pagination .number,
  .el-pagination button:disabled,
  .el-pagination .btn-next {
    background: transparent;
  }
  .el-pagination .btn-quicknext {
    background: transparent;
  }
  .el-pagination .btn-prev {
    background: transparent;
  }
  .el-pagination .btn-quickprev {
    background: transparent;
  }
  .active {
    background: $ui-red !important;
  }
}
