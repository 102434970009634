@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-dropdown-format-icon {
  margin-right: 4px;
  font-size: 14px;
}
.report-dropdown-format-arrow-icon {
  margin-left: 4px;
  font-size: 14px;
}
