@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.reporter-cell {
  transition: all 0.1s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }

  .name-container {
    display: flex;
    align-items: center;
  }
  .inactive-banner {
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    display: inline-block;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: "Campton-Medium";
    margin: 0px;
    border-color: $ui-grey;
    color: $ui-subtitle;
    cursor: default;
  }
  .reporter-job {
    font-family: "Campton-Medium";
    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .company-container {
    display: flex;
    align-items: center;
  }

  .reporter-row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .reporter-container {
    display: flex;
    flex-direction: row;

    .reporter-title {
      text-align: left;
      color: $ui-text;
      font-weight: 400;
      font-size: 20px;
      padding-left: 10px;
    }

    .right-content {
      @media (min-width: 992px) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
