@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.timeline-comment {
  background-color: $ui-background;
  margin-left: -25px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border-left-width: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  .timeline-icon-wrapper {
    background-color: transparent !important;
    margin-left: 10px !important;
  }
  .timeline-content-container {
    .content {
      .comment {
        padding: 0px;
        padding-top: 5px;
        padding-right: 10px;
        background-color: $ui-background;
        text-align: left;
        border-radius: 12px;
        font-size: 14px;
        line-height: 16px;
        color: $ui-subtitle;
      }
    }
  }
}
.notme-avatar {
  display: flex;
  margin-left: -4px;
  .notme-avatar-text {
    font-family: "Campton-Medium";
  }
}
