@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.notme-avatar {
  display: inline-block;
  font-weight: 400;
  width: var(--size);
  height: var(--size);
  background-color: var(--color);
  border-radius: 50%;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0;
  text-align: center;

  .notme-avatar-text {
    position: relative;
    color: #fff;
    font-family: "Campton-Bold";
    font-size: calc(var(--size) / 2.6);
    line-height: calc(var(--size));
    margin: 0 auto;
    text-transform: uppercase;

    &--3 {
      font-size: 0.9rem;
    }
    &--4 {
      font-size: 0.8rem;
    }
    &--5 {
      font-size: 0.7rem;
    }
    &--6 {
      font-size: 0.6rem;
    }
  }
  .counter-container {
    margin-top: -12px;
    margin-right: -6px;
    display: flex;
    justify-content: flex-end;
    .counter {
      background-color: $ui-subtitle;
      border-radius: 4px;
      border: 1px solid $ui-white;
      p {
        margin-bottom: 0px;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2px;
        color: $ui-white;
        font-family: "Campton-Medium";
      }
    }
  }
}
