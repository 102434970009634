@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.user-dropdown {
  display: block;
  position: initial;
  .user-container {
    position: relative;
    padding: 5px 20px 5px 5px;
    margin: 5px 20px 5px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    color: $ui-subtitle;
    transition:
      color 0.5s,
      background-color 1s,
      font-weight 0.1s ease;
    .company-logo {
      object-fit: contain;
      border-radius: 6px;
      -webkit-box-shadow: $widget-shadow;
      box-shadow: $widget-shadow;
      height: 40px;
      width: 40px;
      margin-right: 16px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .user-info {
      overflow: hidden;
    }
  }
  .avatar-circle {
    @include avatar(45px);
  }
  .profile-name {
    font-family: "Campton-Bold";
    margin: 0;
    color: $ui-text;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;
    text-align: left;
  }
  .profile-role {
    font-family: "Campton-Book";
    color: $ui-subtitle;
    font-size: 14px;
    margin-bottom: 0px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
  }
  .el-dropdown {
    position: inherit;
    display: inherit;
  }
  .info-button {
    font-family: "Avenir";
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 12.5px;
    top: 17px;
    right: 15px;
    background-color: $ui-lighter_grey;
    z-index: 10;
    &:hover {
      -webkit-box-shadow: $widget-shadow;
      -moz-box-shadow: $widget-shadow;
      box-shadow: $widget-shadow;
    }

    .info-button-icon {
      font-size: 10px;
      text-align: center;
      display: inline;
      line-height: normal;
      vertical-align: baseline;
      top: 25%;
      color: $ui-text;
    }
  }
}
.user-dropdown-menu {
  text-align: left;
  .dropdown-profile-item {
    cursor: default;
  }
  .dropdown-profile-item:hover {
    background: none;
  }
  .dropdown-other-item:hover {
    background: $ui-light_blue;
    color: white;
  }
}
