@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.timeline-resolution {
  .timeline-content-container {
    width: 100%;
    .resolution-content {
      background-color: $ui-background;
      margin-top: 5px;
      margin-bottom: 5px;
      border-radius: 10px;
      border-left-width: 0px !important;
      padding: 12px;
      width: 100%;
      .conclusions_selected {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 4px;
        margin-bottom: 6px;
        font-size: 18px;
        .notme-tag {
          font-size: 14px;
        }
      }
      .text-details {
        margin-top: 10px;
        font-family: "Campton-Medium";
        color: $ui-text;
        text-align: left;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 0px;
      }
    }
  }
}
