@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-cell-mini {
  text-decoration: none;
  .report-container {
    border-radius: 12px;
    transition: all 0.1s ease-in-out;
    padding: 10px;
    text-align: left;
    &:hover {
      transform: scale(1.005);
      cursor: pointer;
    }
    a {
      color: $ui-text;
      text-decoration: none;
    }
    p {
      margin-bottom: 0px;
    }
    .report-header,
    .report-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .report-header {
      padding-bottom: 10px;
      .report-title {
        font-family: "Campton-Bold";
        color: $ui-dark_blue;
      }
      .user-name {
        font-size: 15px;
        font-family: "Campton-Book";
        color: $ui-text;
        .company-logo {
          margin-right: 5px;
        }
      }
    }
    .report-body {
      .date-container {
        p {
          color: $ui-subtitle;
          font-size: 14px;
          font-family: "Campton-Medium";
        }
      }
    }
  }
}
