@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-step {
  p {
    margin-bottom: 5px;
    font-size: 16px;
    color: $ui-text;
  }
  padding-top: 20px;
}

.report-step + .report-step {
  border-top: 1px solid $ui-light_grey;
}
.report-step:last-child {
  @media (max-width: 767px) {
    border-bottom: 1px solid $ui-light_grey;
  }
}
