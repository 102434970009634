@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.location-cell {
  transition: all 0.1s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }

  .location-row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .location-container {
    display: flex;
    flex-direction: row;

    .left-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }

    .right-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }

    .location-title {
      text-align: left;
      color: $ui-text;
      font-family: "Campton-Medium";
      font-size: 16px;
    }
    .location-subtitle {
      text-align: left;
      color: $ui-dark_grey;
      font-family: "Campton-Book";
      font-size: 14px;
    }

    .location-circle {
      width: 50px;
      height: 50px;
      background-color: $ui-lighter_grey;
      text-align: center;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;

      img {
        position: relative;
        top: 25%;
        width: 25px;
        line-height: 25px;
        font-family: "Campton-Medium";
      }
    }

    .spinner {
      font-size: 30px;
      margin: 10px 0px;
      color: $ui-lighter_grey;
    }
  }
}
