@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-cell {
  border-radius: 12px;
  border-left-width: 0.75rem;
  border-left-style: solid;
  border-color: rgba(var(--color), 1);
  transition: all 0.1s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  &:hover {
    transform: scale(1.005);
    cursor: pointer;
  }
  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }
  .report-row {
    margin-right: 0px;
    margin-left: 0px;
    .tags-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px 0px;
    }
  }
  .right-text {
    @media (max-width: 767px) {
      text-align: right;
    }
  }
  .tag-icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-info {
      margin-left: 5px;
    }
  }
  .report-container {
    display: flex;
    flex-direction: row;

    .left-checkbox {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }
    .company-name {
      font-family: "Campton-Medium";
    }
    .username-text {
      font-size: 16px;
      display: flex;
      align-items: center;
      height: 30px;
      @media (min-width: 767px) {
        margin-bottom: 8px;
      }
      i {
        display: inline;
        margin-bottom: 10px;
        margin-left: 5px;
        color: $ui-light_blue;
      }
    }

    .assignees {
      display: block !important;
      ul {
        margin-top: 5px;
        padding-inline-start: 10px;
      }
    }

    .assignees-small {
      vertical-align: middle !important;
      ul {
        padding-inline-start: 10px;
      }
    }

    .report-title-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-bottom: 8px;

      .report-title {
        text-align: left;
        color: $ui-text;
        font-family: "Campton-Book";
        font-size: 20px;
        display: inline-block;
      }
      .report-icon {
        height: 28px;
        .icon-info {
          margin-left: 2px;
        }
      }
    }
  }
}
