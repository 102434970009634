@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.notme--text-area {
  flex-direction: column;
  margin-bottom: 16px;

  .notme--text-area--label {
    font-family: "Campton-Medium";
    font-size: 16px;
    color: $ui-text;
    margin-bottom: 0px;
    float: left;
  }

  .notme--text-area--right-label {
    font-family: "Campton-Medium";
    color: $ui-blue;
    font-size: 14px;
    float: right;
    margin-bottom: 0px;
    text-align: right;
  }

  .text-area {
    -webkit-appearance: none;
    outline: none;
    border-radius: 0px;
    border: 1px solid $ui-light_grey;
    background-color: #fff;
    padding: 10px;
    width: 100%;
    resize: none;
    &:focus {
      outline: none;
      border: 1px solid $ui-red;
    }
  }
}
