@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.category-list {
  margin: 0;
  padding: 10px 0px;
  list-style: none;
  .category-list-item {
    margin: 0px;
    text-align: left;
    padding: 5px 14px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    text-transform: capitalize;
    cursor: pointer;
    transition: ease-in-out 0.25s;
    color: $ui-text;
    font-family: "Campton-Medium";
    font-size: 15px;
    &:hover {
      background: $ui-lighter_grey;
    }
    .fa-icon {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 26px;
    }
  }
}
