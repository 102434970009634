@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.category-step {
  .category-step--header {
    .category-step--header-title {
      text-transform: uppercase;
      font-family: "Campton-Medium";
      color: $ui-text;
      margin-bottom: 0px;
      font-size: 14px;
      color: $ui-blue;
    }
  }
}
