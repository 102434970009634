@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.user-cell {
  li {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .avatar-circle {
    @include avatar(40px);
  }

  .right-btn {
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-left: auto;
    font-size: 10px;
  }

  .user-info {
    margin-left: 16px;
    align-self: center;
    .profile-name {
      font-family: "Campton-Bold";
      margin: 0;
      color: $ui-text;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
    }
    .profile-role {
      font-family: "Campton-Book";
      color: $ui-subtitle;
      font-size: 14px;
      margin-bottom: 0px;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-transform: capitalize;
    }
  }
}
