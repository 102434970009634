@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.filter-popover {
  border-radius: 13px;
  background-color: $ui-card;
  -webkit-box-shadow: $widget-shadow;
  -moz-box-shadow: $widget-shadow;
  box-shadow: $widget-shadow;

  .title {
    font-family: "Campton-Book";
    font-size: 20px;
    font-weight: 600;
    color: $ui-text;
    border-bottom: 0.5px solid $ui-light_grey;
  }

  .text {
    font-family: "Campton-Book";
    font-size: 15px;
    font-weight: 500;
    color: $ui-text;
  }

  .category {
    margin-top: 10px;
    .title {
      font-family: "Campton-Medium";
      font-size: 16px;
      font-weight: 500;
      color: $ui-text;
      border-bottom: none;
    }
    border-bottom: 0.5px solid $ui-light_grey;
  }

  .el-select .el-input__inner {
    background-color: $ui-card;
    &::placeholder {
      color: $ui-text;
    }
    color: $ui-text;
  }
}
