@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.conclusions {
  position: relative;
  height: 100%;
  .title {
    font-size: 14px;
    text-align: left;
    font-family: "Campton-Medium";
    margin-bottom: 6px;
  }

  .multiselect__content-wrapper {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-color: $ui-light_grey;
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
    z-index: 20;
    max-height: 250px !important;
    ul {
      li {
        margin-top: 0px;
        cursor: pointer;
        .option__desc {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 15px;
          font-weight: 400;
          color: $ui-text;
          font-family: "Campton-Medium" !important;
        }
      }
    }
  }

  .multiselect__select {
    display: none;
  }

  .multiselect__tags {
    margin-top: 5px;
    padding: 5px 10px;
    border: 1px solid $ui-light_grey;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .multiselect__input,
    .multiselect__placeholder {
      color: $ui-text;
      background-color: inherit;
      margin: 5px 0;
      width: 90% !important;
    }

    .multiselect__tags-wrap {
      display: none;
    }
  }

  .multiselect__option {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: pre-wrap;
    color: $ui-text;
    padding: 8px;
    .option__left-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .option__square {
        width: 10px;
        height: 10px;
        border-radius: 2.5px;
        background-color: var(--color);
      }
      .option__title {
        font-size: 15px;
        margin-left: 5px;
        font-weight: 400;
        color: $ui-text;
        font-family: "Campton-Medium" !important;
      }
    }
    &--highlight {
      background-color: $ui-light_grey;
    }
  }

  .multiselect__option--highlight {
    &:after {
      display: none;
    }
  }

  .multiselect__option--selected {
    &:after {
      display: none;
    }
  }

  .conclusions_selected {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    .notme-tag {
      white-space: nowrap;
      font-size: 13px;
      margin-right: 0px;
      .tag-removal {
        padding: 5px 2.5px 5px 5px;
        color: $ui-grey;
        font-size: 12px;
        cursor: pointer;
        z-index: 10;
      }
    }
  }

  .add-resolutions-prompt {
    margin-top: 20px;
    &__title {
      font-weight: bold;
      font-size: 17px;
      color: $ui-subtitle;
      font-family: "Campton-Bold";
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 15px;
      color: $ui-subtitle;
      font-family: "Campton-Medium";
    }
  }
}
