@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-card {
  text-align: left;
  padding-bottom: 20px;

  .report-card-header {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;
    flex-direction: row;
    justify-content: space-between;

    .report-card-header--title {
      display: flex;
      flex-direction: row;
      align-items: center;

      .report-card-header--count {
        margin-left: 10px;
        font-family: "Campton-Bold";
        margin-bottom: 4px;
        font-size: 14px;
        color: $ui-text;
      }
    }

    .report-card-header--right-content {
      .report-card-header-btn {
        margin-left: 10px;
        height: 28px;
        width: 28px;
        padding: 6px;
      }
    }

    h5 {
      color: $ui-text;
      font-size: 17px;
      font-family: "Campton-Bold" !important;
      margin-bottom: 0;
    }

    .next-icon {
      margin-left: 20px;
    }
  }

  .title {
    font-size: 17px;
    color: $ui-subtitle;
    font-family: "Campton-Bold";
  }

  .subtitle {
    color: $ui-subtitle;
    font-size: 15px;
    font-family: "Campton-Medium";
    margin: 0;
    margin-top: 8px;
  }

  .resolved_subtitle {
    color: $ui-green;
  }

  .info-circle {
    color: $ui-dark_grey;
    align-self: center;
    margin-left: 10px;
    padding-top: 4px;
    font-size: 16px;
  }

  .report-card-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li + li {
      margin-top: 20px;
    }
    .text-details {
      margin-bottom: 0px;
    }
  }
}
