@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-document-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .file-selection {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background-color: $ui-background;
    padding: 8px;
    border-radius: 8px;
    width: 100%;

    .icon-container {
      background-color: $ui-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 6px;
      i {
        font-size: 16px;
        color: $ui-white;
      }
    }

    span {
      color: $ui-text;
      font-family: "Campton-Medium";
    }
  }

  .separator {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $ui-light_grey;
    line-height: 0.1em;
    margin: 20px 0;

    span {
      background: $ui-white;
      font-family: "Campton-Medium";
      padding: 0 10px;
    }
  }

  .url-input {
    width: 100%;
  }

  .report-document-modal-footer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
  }
}
.files-to-upload-container {
  background-color: #5f9e831a;
  padding: 15px 10px;
  border-radius: 10px;
  margin-bottom: 25px;
  .upload-button {
    margin-top: 10px;
  }

  .files-to-upload--uploading {
    text-align: center;
    padding: 10px;
    color: $ui-text;
    .files-to-upload--uploading-text {
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}
