@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report {
  margin-bottom: 20px;

  .report-header {
    &__actions-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      &-icon {
        margin-right: 4px;
        font-size: 14px;
      }
    }
  }

  .report-btn-header {
    font-size: 14px;
    line-height: 14px;
    @media (min-width: 576px) {
      padding: 12px;
      height: 40px;
      width: 40px;
    }
  }

  .container {
    @media (max-width: 768px) {
      max-width: none;
    }
  }

  .report-content-container {
    border: 1px $ui-light_grey solid;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 0px;
      border: none;
    }

    .report-column {
      @media (max-width: 768px) {
        padding: 0px 5px;
      }
    }
  }

  .top-button {
    border-radius: 6px;
    padding: 4px 16px 2px 16px;
    margin: 5px 0px 5px 10px;
    @media (max-width: 576px) {
      margin: 0 0 8px;
      display: block;
      width: 100%;
    }
  }

  .loader-middle {
    top: 200px;
  }

  .report-card {
    text-align: left;

    .subtitle {
      color: $ui-subtitle;
      font-family: "Campton-Bold";
    }

    .sidebar-list {
      margin: 0;
      padding: 0;
      list-style: none;
      li + li {
        margin-top: 30px;
      }
    }
  }
}
