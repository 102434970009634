@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.timeline-event {
  .timeline-tags-container {
    position: relative;
    display: inline;
    margin-right: -5px;
  }
  .timeline-tag-container {
    position: relative;
    display: inline;
    top: -2px;
    .notme-tag {
      margin: 0px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
