@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.no-data {
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
  max-width: 567px;
  @media (max-width: 767px) {
    margin-top: 80px;
  }
  h4 {
    font-size: 1.25rem;
    color: $ui-text;
  }
}
