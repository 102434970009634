@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~effect-input/dist/index.css";

.signup {
  .signup-header {
    text-align: left;
    margin-bottom: 30px;
  }
  .el-checkbox__label {
    white-space: normal;
    vertical-align: middle;
    word-break: break-all;
    word-wrap: break-word;
  }
  .signup-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
