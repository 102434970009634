@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-notes {
  margin-top: 20px;

  .ql-editor {
    font-family: "Campton-Medium";
  }

  .ql-toolbar.ql-snow {
    border-width: 2px 2px 0px 2px;
    border-radius: 0.5em 0.5em 0px 0px;
    border-color: $ui-background;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 2px solid $ui-background;
    border-radius: 0px 0px 0.5em 0.5em;
  }

  .save-notes-button {
    margin-top: 10px;
  }
}
