@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.notme-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $ui-white;
  background-clip: border-box;
  border-radius: 0.25rem;
  padding: 1.25rem;

  .flat-card {
    background-color: red;
  }

  .notme-card-header {
    display: flex;
    align-items: center;
    padding: 0 0 1.25rem 0;

    .notme-card-header--title {
      font-family: "Campton-Medium";
      color: $ui-text;
      font-size: 22px;
      margin-bottom: 0;
    }

    .next-icon {
      margin-left: 20px;
    }

    .prompt {
      color: $ui-dark_grey;
      margin-left: 10px;
      font-size: 18px;
    }
  }

  .notme-card-body {
    flex: 1 1 auto;
    min-height: 1px;
  }
}

.notme-card--flat {
  background-color: $ui-white;
  border: 1px solid $ui-light_grey;
  border-radius: 12px;
}
