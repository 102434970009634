@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.user-category-cell {
  padding: 6px 6px 6px 12px;
  cursor: pointer;
  &:hover {
    background: $ui-lighter_grey;
  }
  .user-category-cell-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .user-category-texts {
    .user-info-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
    .user-name,
    .user-role {
      margin: 0px;
      padding: 0px;
      text-align: left;
      font-family: "Campton-Medium";
    }
    .user-name {
      font-size: 15px;
      text-transform: capitalize;
    }
    .user-role {
      font-size: 14px;
      color: $ui-subtitle;
    }
  }
}
