@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in;
  max-height: 230px;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
  max-height: 0px;
}

.reports {
  .multiselect__tag {
    background: none;
    border: 1px solid $ui-red;
    color: $ui-red;
    font-family: "Campton-Medium";
    border-radius: 20px;
    .multiselect__tag-icon {
      &:hover {
        background: none;
      }
      &:hover:after {
        font-family: "Campton-Medium";
        color: $ui-red;
      }
      &:after {
        color: $ui-text;
      }
    }
  }

  .reports-top-right-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 0px;
    margin-left: 20px;
    @media (max-width: 575px) {
      flex-direction: column;
      margin: 0px;
    }
  }

  .new-report-button {
    background-color: $ui-red;
    color: white;
    &:hover,
    &:active,
    &:focus {
      background-color: $ui-red;
      color: white;
      font-family: "Campton-Bold";
      border: none;
    }
  }

  .row-filters {
    text-align: left;
    margin-bottom: 20px;

    .clear-filter {
      float: right;
      font-size: 12px;
      font-family: "Campton-Medium";
      padding-top: 2px;
      color: $ui-red;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $ui-text;
      }
    }

    h6 {
      text-transform: uppercase;
      letter-spacing: 0.75px;
      color: #999;
      display: block;
      margin: 0 0 8px;
      font-size: 12px;
      font-family: "Campton-Medium";
      line-height: 1.5em;
    }
  }

  .loader-middle {
    top: 200px;
  }

  .toolbar-banner {
    position: fixed;
    background: #fff;
    padding: 16px;
    border-radius: 36px;
    bottom: calc(#{$layout-padding-size} + 30px);
    left: $sidebar-width-expanded;
    right: 0;
    margin: 0 auto;
    z-index: 1100;
    width: 100%;
    max-width: 496px;
    box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.15);

    @media (max-width: 767px) {
      bottom: $layout-padding-size;
      left: $layout-padding-size;
      right: $layout-padding-size;
      max-width: 496px;
    }
    @media (max-width: 590px) {
      max-width: 396px;
    }
    @media (max-width: 490px) {
      max-width: 300px;
    }
    .toolbar-container {
      .toolbar-container-content {
        display: flex;
        align-items: center;
        flex-direction: row;
        .number {
          display: inline-block;
          margin-right: 5px;
          font-family: "Campton-Medium";
          font-size: 12px;
          border-radius: 50%;
          min-width: 20px;
          min-height: 20px;
          padding: 5px;
          background: #e4405ea8;
          color: white;
          text-align: center;
          line-height: 1;
          box-sizing: content-box;
          white-space: nowrap;
        }
        .number:before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          padding-top: 100%;
          height: 0;
        }
        .number span {
          display: inline-block;
          vertical-align: middle;
        }
        p {
          font-family: "Campton-Medium";
          color: $ui-text;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        .clear {
          cursor: pointer;
          &:hover {
            color: $ui-red;
          }
        }
      }
    }
  }

  .selection-toolbar-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    text-align: center;
    z-index: 1100;

    @media (min-width: 420px) {
      bottom: 24px;
    }

    .selection-toolbar-content {
      display: block;
      margin-bottom: 0;
      margin-left: $sidebar-width-expanded;

      @media (max-width: 767px) {
        margin: 0 20px 10px;
      }

      .selection-toolbar {
        // z-index: 1000;
        // width: 600px;//calc(100% - 237px - 32px - 32px);
        // height: 70px;
        background-color: $ui-card;
        -webkit-box-shadow: $widget-shadow;
        -moz-box-shadow: $widget-shadow;
        box-shadow: $widget-shadow;
        border-radius: 50px;
        // position: fixed;
        // bottom: 50px;
        // margin-left: 270px;
        // margin-right: 270px;
        transition: opacity 0.5s;
        margin: 0 auto;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .number {
          display: inline-block;
          margin-right: 5px;
          font-family: "Campton-Bold";
          font-size: 12px;
          border-radius: 50%;
          min-width: 20px;
          min-height: 20px;
          padding: 5px;
          background: #e4405ea8;
          color: white;
          text-align: center;
          line-height: 1;
          box-sizing: content-box;
          white-space: nowrap;
        }
        .number:before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          padding-top: 100%;
          height: 0;
        }
        .number span {
          display: inline-block;
          vertical-align: middle;
        }

        p {
          font-family: "Campton-Medium";
          color: $ui-text;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
