@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-file-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  .report-file-upload--icon {
    padding-right: 1rem;
  }

  .report-file-upload--title {
    word-break: break-word;
  }

  .report-file-upload--remove-icon {
    color: $ui-red;
    justify-content: center;
    text-align: center;
    vertical-align: bottom;
    margin-left: auto;
    margin-right: 0;
    align-self: center;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $ui-dark_red;
    }
  }
}
