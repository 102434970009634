@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.btn-shape {
  border-radius: 20px;
  border-width: 0px;
  height: 100%;
  margin-left: 10px;
  text-align: right;
  &:hover {
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
  }
}
