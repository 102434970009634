@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.tags {
  margin-top: -20px;
  margin-right: -10px;
  margin-left: -10px;
  .multiselect.multiselect__hidden {
    display: none;
  }
  .multiselect__content-wrapper {
    ul {
      li {
        margin-top: 0px;
        .option__desc {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .option__title {
            display: flex;
            justify-items: center;
            align-items: center;
          }
          i {
            color: $ui-text;
            font-size: 12px;
          }
        }
      }
    }
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-color: $ui-light_grey;
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
    z-index: 20;
  }

  .multiselect__transparent {
    .multiselect__tags {
      border: 1px solid transparent;
    }
  }

  .multiselect__option {
    display: flex;
    flex-direction: row;
    white-space: pre-wrap;
    background-color: $ui-card !important;
    color: $ui-text;
    .option__left-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .option__square {
        width: 10px;
        height: 10px;
        border-radius: 2.5px;
        background-color: var(--color);
      }
      .option__title {
        font-size: 15px;
        margin-left: 5px;
        font-weight: 400;
        color: $ui-text;
        font-family: "Campton-Medium" !important;
      }
    }
  }
  .multiselect__select {
    display: none;
  }

  .multiselect__tags {
    margin-top: 5px;
    padding: 5px 10px 5px 10px;
    border: 1px solid $ui-light_grey;
    border-radius: 8px;
    .multiselect__input {
      background-color: inherit;
      margin-top: 10px;
    }
    .multiselect__placeholder {
      color: $ui-text;
    }
    .tag-selected-option {
      display: inline-block;
      padding: 5px 8px 5px 8px;
      margin: 5px 5px 5px 0px;
      border-radius: 5px;
      background-color: var(--color-bg);
      .tag-selected-option-inner {
        color: var(--color);
        font-family: "Campton-Medium";
        display: flex;
        font-size: 13px;
        justify-content: space-between;
        align-items: center;
        .tag-removal {
          padding: 5px 2.5px 5px 5px;
          color: $ui-grey;
          font-size: 12px;
          cursor: pointer;
          z-index: 10;
        }
      }
    }
  }

  .multiselect__option--highlight {
    &:after {
      display: none;
    }
  }

  .multiselect__option--selected {
    &:after {
      display: none;
    }
  }

  .multiselect__tag {
    background: none;
    border: 1px solid $ui-grey;
    color: $ui-text;
    font-family: "Campton-Medium";
    border-radius: 5px;
    margin-right: 0px;
    margin-bottom: 0px;
    white-space: break-spaces;
    text-overflow: inherit;
    .multiselect__tag-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: none;
      }
      &:hover:after {
        font-family: "Campton-Bold";
        color: $ui-red;
      }
      &:after {
        color: $ui-text;
        height: 20px;
        width: 20px;
      }
    }
  }

  .master-selector--title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    .master-selector--title-text {
      margin: 0px 10px 0px 0px;
      color: $ui-text;
      font-family: "Campton-Medium";
    }

    .clear-filter {
      float: right;
      font-size: 12px;
      font-family: "Campton-Medium";
      padding-top: 2px;
      color: $ui-red;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $ui-text;
      }
    }

    .info-circle {
      color: $ui-dark_grey;
      align-self: center;
      padding-top: 4px;
      font-size: 16px;
    }
  }

  .el-input__inner {
    border-radius: 6px;
  }
}
