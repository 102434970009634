@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.notme-checkbox {
  position: relative;
  padding-left: 34px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 10px;

  &.disabled {
    cursor: not-allowed;
    color: $ui-grey;
  }
  .notme-checkbox--input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .notme-checkbox--checkmark {
    position: absolute;
    top: calc(50% - 11px);
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    border-width: 1px;
    color: transparent;
    font-size: 14px;
    padding-left: 2px;
    padding-top: 2px;
  }
}

.notme-checkbox:hover .notme-checkbox--input ~ .notme-checkbox--checkmark {
  background-color: $ui-light_grey;
}

.notme-checkbox .notme-checkbox--input:disabled ~ .notme-checkbox--checkmark {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.notme-checkbox--checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox--checkmark:after {
  display: block;
}

@mixin notme-checkbox-theme($color) {
  border: 1px solid $color;
  background-color: $color;
  color: $ui-white;
}
@mixin notme-checkbox-theme-outline($color) {
  border-color: $color;
  border-style: solid;
}

.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-blue--checkmark {
  @include notme-checkbox-theme($ui-blue);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-light-blue--checkmark {
  @include notme-checkbox-theme($ui-light_blue);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-dark-blue--checkmark {
  @include notme-checkbox-theme($ui-dark_blue);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-red--checkmark {
  @include notme-checkbox-theme($ui-red);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-light-red--checkmark {
  @include notme-checkbox-theme($ui-light_red);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-dark-red--checkmark {
  @include notme-checkbox-theme($ui-dark_red);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-green--checkmark {
  @include notme-checkbox-theme($ui-green);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-dark-green--checkmark {
  @include notme-checkbox-theme($ui-dark_green);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-yellow--checkmark {
  @include notme-checkbox-theme($ui-yellow);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-dark_yellow--checkmark {
  @include notme-checkbox-theme($ui-dark_yellow);
}

.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-blue--checkmark {
  @include notme-checkbox-theme-outline($ui-blue);
}
.notme-checkbox
  .notme-checkbox--input
  ~ .notme-checkbox-light-blue--checkmark {
  @include notme-checkbox-theme-outline($ui-light_blue);
}
.notme-checkbox
  .notme-checkbox--input
  ~ .notme-checkbox-dark-blue--checkmark {
  @include notme-checkbox-theme-outline($ui-dark_blue);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-red--checkmark {
  @include notme-checkbox-theme-outline($ui-red);
}
.notme-checkbox
  .notme-checkbox--input
  ~ .notme-checkbox-light-red--checkmark {
  @include notme-checkbox-theme-outline($ui-light_red);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-dark-red--checkmark {
  @include notme-checkbox-theme-outline($ui-dark_red);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-green--checkmark {
  @include notme-checkbox-theme-outline($ui-green);
}
.notme-checkbox
  .notme-checkbox--input
  ~ .notme-checkbox-dark-green--checkmark {
  @include notme-checkbox-theme-outline($ui-dark_green);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-yellow--checkmark {
  @include notme-checkbox-theme-outline($ui-yellow);
}
.notme-checkbox
  .notme-checkbox--input
  ~ .notme-checkbox-dark_yellow--checkmark {
  @include notme-checkbox-theme-outline($ui-dark_yellow);
}
