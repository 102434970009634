@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.sidebar {
  .notme-logo {
    height: $sidebar-logo-height;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li:first-child {
      margin-top: 26px;
    }
  }

  .menu {
    overflow-x: hidden;
    height: calc(
      100vh -
        (
          #{$sidebar-footer-height} + #{$sidebar-logo-height} + 2 *
            #{$sidebar-logo-padding-top}
        )
    );
    position: fixed;
    width: $sidebar-width-expanded;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .menu__logo:hover {
    text-decoration: none;
  }

  .menu-setting-padding {
    height: calc(
      100vh -
        (
          #{$sidebar-footer-height} + #{$sidebar-logo-height} + 2 *
            #{$sidebar-logo-padding-top} + #{$sidebar-back-height}
        )
    );
  }

  .fixed-header {
    padding-top: $sidebar-logo-padding-top;
    padding-bottom: $sidebar-logo-padding-top;
    border-bottom: 1px solid $ui-light_grey;
  }

  .back-footer {
    position: absolute;
    background-color: $ui-card;
    height: $sidebar-back-height;
    width: 100%;
    bottom: $sidebar-footer-height;
  }

  .fixed-footer {
    position: absolute;
    -webkit-backdrop-filter: saturate(50%) blur(20px);
    backdrop-filter: saturate(50%) blur(20px);
    background-color: $ui-card;
    bottom: 0;
    width: 100%;
    height: $sidebar-footer-height;
  }
}
