@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.file-preview-modal {
  .modal-open-file,
  .modal-delete-file {
    text-align: center;
    float: right;
    font-size: 12px;
  }
  .modal-delete-file {
    margin-right: 5px;
  }
  .modal-text {
    margin-top: 8px;
    margin-bottom: 0px;
    color: $ui-text;
    font-family: "Campton-Medium";
    font-size: 16px;
  }
  .custom-modal-header,
  .modal-info {
    text-align: left;
  }
}
