@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.date-picker-container {
  .date-picker-header {
    display: flex;
    justify-content: space-between;
    .clear-filter {
      float: right;
      font-size: 12px;
      font-weight: 700;
      padding-top: 2px;
      color: $ui-red;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $ui-dark_blue;
      }
    }
  }
  .el-input__inner,
  .el-range-input {
    cursor: pointer;
    color: $ui-dark_blue;
    .el-range__close-icon {
      display: none;
    }
  }
}
.analytics {
  padding-right: 0px;
  padding-left: 0px;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
}
