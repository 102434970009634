@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.report-chat-text-area {
  display: block;
  flex: 1 1 auto;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border: 0;
  outline-width: 0px;
}
