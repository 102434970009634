@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.master-selector {
  .master-selector--container {
    background-color: $ui-lighter_grey;
    border-radius: 10px;
    margin: 0px 20px;
    padding: 10px;
    text-align: left;
    .option__image {
      width: 18px;
      display: inline-block;
      vertical-align: middle;
      object-fit: contain;
    }

    .multiselect__content-wrapper {
      ul {
        li {
          &:first-child {
            margin-top: 0px;
          }
          .option__desc {
            display: flex;
            justify-items: center;
            align-items: center;
            .option__title {
              display: flex;
              justify-items: center;
              align-items: center;
            }
          }
        }
      }
    }

    .multiselect__option {
      display: flex;
      flex-direction: row;
      white-space: pre-wrap;
      .option__title {
        font-size: 14px;
        margin-left: 5px;
        color: $ui-text;
        font-family: "Campton-Medium" !important;
      }
    }
    .multiselect__tags {
      background-color: $ui-background;
      .multiselect__input {
        background-color: inherit;
      }
      .multiselect__placeholder {
        color: $ui-text;
      }
    }
    .multiselect__option--highlight {
      background-color: $ui-light_grey !important;
      color: $ui-text;
    }

    .multiselect__tag {
      background: none;
      border: 1px solid $ui-grey;
      color: $ui-text;
      font-family: "Campton-Medium";
      border-radius: 5px;
      margin-right: 0px;
      margin-bottom: 0px;
      white-space: break-spaces;
      text-overflow: inherit;
      .multiselect__tag-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: none;
        }
        &:hover:after {
          font-family: "Campton-Bold";
          color: $ui-red;
        }
        &:after {
          color: $ui-text;
          height: 20px;
          width: 20px;
        }
      }
    }

    .master-selector--title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;
      .master-selector--title-text {
        margin: 0px 10px 0px 0px;
        color: $ui-text;
        font-family: "Campton-Medium";
      }

      .clear-filter {
        float: right;
        font-size: 12px;
        font-family: "Campton-Medium";
        padding-top: 2px;
        color: $ui-red;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $ui-text;
        }
      }

      .info-circle {
        color: $ui-dark_grey;
        align-self: center;
        padding-top: 4px;
        font-size: 16px;
      }
    }

    .el-input__inner {
      border-radius: 6px;
    }
  }
}
.master-selector--select {
  border-radius: 6px;
}
