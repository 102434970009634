@import "@/styles/variables.scss";                           @import "@/styles/global-style.scss";

.dashboard-header {
  .greeting-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    .greeting-text {
      color: $ui-subtitle;
      text-transform: uppercase;
      font-size: 14px;
    }
    .user-text {
      color: $ui-text;
      margin-bottom: 4px;
    }
  }
  .header {
    @media (min-width: 576px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .header-title {
      display: flex;
      flex-direction: row;
    }

    .title {
      text-align: left;
      color: $ui-text;
      font-family: "Campton-Medium" !important;
      font-size: 32px;
    }

    .period-label {
      align-self: center;
      margin-bottom: 0px;
      margin-left: 10px;
      font-family: "Campton-Medium" !important;
    }

    .right-content {
      margin-left: auto;
      margin-right: 0;

      .notme-button {
        margin-left: 10px;
      }

      @media (max-width: 576px) {
        .is-circle {
          border-radius: 25px;
        }
      }

      @media (max-width: 576px) {
        margin-bottom: 15px;
        display: block;
        .notme-button {
          margin-left: 0px;
        }
      }
    }

    .right-content-flex {
      margin-left: 0;
      flex: 1;
    }

    .el-dropdown {
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .info-circle {
      color: $ui-dark_grey;
      align-self: center;
      margin-left: 10px;
      padding-top: 10px;
      font-size: 18px;
    }
  }
  .children-elements {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid $ui-light_grey;
    li {
      display: inline;
      padding-right: 10px;
    }

    .children-router-link {
      padding-bottom: 3px;
      color: $ui-text;
    }

    .children-router-link:hover,
    .children-router-link.router-link-active,
    .children-router-link.router-link-exact-active {
      text-decoration: none;
      border-bottom: 2px solid $ui-blue;
    }

    .children-router-link.router-link-active,
    .children-router-link.router-link-exact-active {
      color: $ui-blue;
      font-family: "Campton-Bold";
    }
  }

  .header-bottom {
    margin-bottom: $layout-padding-size;
    border-bottom: 1px solid $ui-light_grey;
  }
}
